/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #202020;
  }
  
  /* Container for the entire app */
  .app-container {
    position: relative;
  }
  
  /* Navbar */
  .navbar {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
    height: 120px;
    background-color: #000;
    box-shadow: 0px 0px 10px 2px #202020;
    border-radius: 30px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .nav-text {
    font-size: 31px;
    font-weight: bold;
    color: rgb(253, 205, 12);
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .nav-links {
    display: flex;
    gap: 10px;
  }
  
  .nav-link {
    background-color: black;
    color: rgb(229, 221, 0);
    font-size: 11px;
    font-weight: bolder;
    border: none; 
    padding: 10px 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s;
  }
  
  .nav-link:hover {
    opacity: 1;
  }
  
  /* Example styling for form, etc. (You can customize further) */
  input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* For demonstration: page heading style */
  h2 {
    font-weight: bold;
  }
  
  