/* Global resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  min-height: 100vh; 
  overflow-x: hidden;
  scroll-behavior: smooth;
}



/* Content Container */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 160px; /* So content doesn't hide behind navbar */
  width: 100%;
  min-height: 100vh;
  transition: background 0.3s ease-in-out;
}

/* Pane / Page Content */
.panel {
  background: #fff;
  width: 80%;
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 12px;
  text-align: center;
}

/* Responsive */
@media (max-width: 768px) {
  .navbar {
    width: 90%;
    height: auto;
    padding: 10px;
  }
  .nav-text {
    font-size: 20px;
  }
  .nav-link {
    font-size: 12px;
    padding: 6px 10px;
  }
  .panel {
    width: 90%;
  }
}


.filter-button {
  margin: 0 5px;
  padding: 8px 16px;
  background-color: #444;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
}

.filter-button:hover {
  background-color: #666;
  transform: scale(1.05);
}